import React, { ReactElement, useCallback, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import './PropertyVirtualTour.scss'

import Seo from '../../components/Seo/Seo'
import MapIcon from '../../assets/svg/map.svg'
import UnitIcon from '../../assets/svg/unit.svg'
import next from '../../assets/images/next.png'
import back from '../../assets/images/back.png'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const PropertyNavigationSliced = loadable(() =>
  import('../../components/PropertyNavigationSliced/PropertyNavigationSliced'),
)

type TowerTypes = {
  name: string
  locationLandmarks?: {
    locationBrief: string
    locationDefault: string
    locationSpecific: string
    latitude: string
    longitude: string
    landmarksEstablishment?: {
      items: {
        establishmentName: string
        establishmentBody: string
        establishmentDistance: string
        establishmentLocation: string
        imagesGallery: {
          description: string
          url: string
          featured: boolean
        }[]
        longitude: string
        latitude: string
      }[]
    }
  }
  overviewDetails: {
    panoramas?: {
      url: string
      featured: string
    }[]
  }
}

type PropertyDataTypes = {
  id: string
  originalId: string
  name: string
  overviewDefault: string
  slug: string
  logo: string
  projectName: string
  propertyPrice: string
  propertySizeFloorArea: string
  propertySizeLotArea: string
  propertyType: string
  propertyPriceRangeHighest: string
  propertyPriceRangeLowest: string
  image1: string
  image2: string
  image3: string
  towers: TowerTypes[]
  location: string
}

type NearbyEstablishmentsProps = {
  name: string
  image?: string
  position: {
    lat: number
    lng: number
  }
}

type PropertyVirtualTourPropTypes = {
  data: {
    propertyData: PropertyDataTypes
  }
}

const PropertyVirtualTour = ({
  data: { propertyData },
}: PropertyVirtualTourPropTypes): ReactElement => {
  const [mainTower] = useState<TowerTypes | null>(
    propertyData.towers ? propertyData.towers[0] || null : null,
  )

  const sliderRef = useRef<Slider>(null)
  const nextModelUnit = useCallback(() => {
    if (sliderRef?.current?.slickNext) {
      sliderRef.current.slickNext()
    }
  }, [sliderRef])
  const prevModelUnit = useCallback(() => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef.current.slickPrev()
    }
  }, [sliderRef])

  const aerialView = mainTower?.overviewDetails?.panoramas
    ? mainTower?.overviewDetails?.panoramas[0]?.url
    : null
  const [unitView, setUnitView] = useState<string[]>(
    mainTower?.overviewDetails?.panoramas?.slice(1).map((item) => item.url) ||
      [],
  )
  const modelUnitLength = mainTower?.overviewDetails?.panoramas
    ? mainTower?.overviewDetails?.panoramas?.length
    : ''
  const [currentTour, setCurrentTour] = useState<any>(aerialView)

  return (
    <Layout>
      <Seo
        title={`${propertyData.name} | Virtual Tour`}
        jsonData={{
          '@type': 'Apartment',
          description: propertyData.overviewDefault,
        }}
      />
      <div className="property-virtual-tour-container">
        <PropertyNavigationSliced
          propertyType={propertyData.propertyType}
          propertySlug={propertyData.slug}
          propertyLocation={propertyData.location}
        />
        <div className="property-virtual-tour">
          <div className="property-virtual-tour-details">
            <div className="property-virtual-tour-header">
              <div className="property-virtual-tour-title-container">
                <h2 className="property-virtual-tour-title">DigiTour</h2>
                <h4 className="property-virtual-tour-title-secondary">
                  Amaia&apos;s Upgraded Virtual Tour
                </h4>
              </div>
              <div className="property-virtual-tour-options">
                <button
                  type="button"
                  className={currentTour === aerialView ? 'active' : ''}
                  onClick={(): void => setCurrentTour(aerialView)}
                >
                  <img src={MapIcon} alt="map icon" />
                  Aerial View
                </button>
                <button
                  type="button"
                  className={currentTour === unitView ? 'active' : ''}
                  onClick={(): void => setCurrentTour(unitView)}
                >
                  <img src={UnitIcon} alt="unit icon" />
                  Model Unit
                </button>
              </div>
            </div>
            <hr />
            {currentTour === unitView ? (
              <div className="model-unit-slider">
                <Slider
                  swipeToSlide={false}
                  arrows={false}
                  dots={false}
                  fade
                  easing="ease"
                  className="banner-slider"
                  ref={sliderRef}
                >
                  {unitView.map((model) => (
                    <iframe
                      key={model}
                      src={model}
                      title="Virtual Tour"
                      frameBorder="0"
                    />
                  ))}
                </Slider>
                {modelUnitLength >= 3 ? (
                  <div className="nav-button-container">
                    <button
                      type="button"
                      className="nav"
                      onClick={(): void => prevModelUnit()}
                    >
                      <img src={back} alt="backIcon" />
                    </button>
                    <button
                      type="button"
                      className="nav"
                      onClick={(): void => nextModelUnit()}
                    >
                      <img src={next} alt="nextIcon" />
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <iframe src={currentTour} title="Virtual Tour" frameBorder="0" />
            )}
            <div className="property-virtual-tour-options-mobile">
              <button
                type="button"
                className={currentTour === aerialView ? 'active' : ''}
                onClick={(): void => setCurrentTour(aerialView)}
              >
                <img src={MapIcon} alt="map icon" />
                Aerial View
              </button>
              <button
                type="button"
                className={currentTour === unitView ? 'active' : ''}
                onClick={(): void => setCurrentTour(unitView)}
              >
                <img src={UnitIcon} alt="unit icon" />
                Model Unit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PropertyVirtualTour

export const pageQuery = graphql`
  query PropertyVirtualTourQuery($id: String!) {
    propertyData: property(id: { eq: $id }) {
      ...PropertyPageFields
    }
  }
`
